import styled from 'styled-components'

export const TextPageContainer = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 100px 15px 60px 15px;
  text-align: justify;

  a {
    text-decoration: underline;
    color: ${props => props.theme.color.textColor};
    word-break: break-all;
  }
`

export const LastUpdated = styled.p`
  font-size: 13px;
  color: ${props => props.theme.color.textColor};
  margin-bottom: 0;
`

export const PageTitle = styled.h1`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${props => props.theme.color.primary};
  /* ${props => props.theme.getTextGradientStyles()} */

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 22px;
  `}
`

export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${props => props.theme.color.primary};
  /* ${props => props.theme.getTextGradientStyles()} */

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 18px;
  `}
`

export const SubTitle = styled.h3`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${props => props.theme.color.textColor};
`

export const Paragraph = styled.p`
  font-size: 15px;
  color: ${props => props.theme.color.textColor};
  margin-bottom: 20px;
`

export const LinkWrapper = styled.span`
  .localized-link {
    font-size: 15px;
    font-weight: 400;
    display: inline;
    height: auto;
    padding: 0;
    text-transform: none;
    color: ${props => props.theme.color.textColor};
  }
`

export const Mark = styled.span`
  font-size: 15px;
  font-weight: bold;
  min-width: 50px;
  margin-right: 15px;
  text-align: right;
  color: ${props => props.theme.color.textColor};
  line-height: 1.25;

  ${props => props.theme.media.lessThan('mobile')`
    min-width: auto;
  `}
`

export const List = styled.ul`
  list-style: none;
`

export const ListItem = styled.li`
  ${Mark} {
    margin-right: 15px;

    ${props => props.theme.media.lessThan('mobile')`
      margin-right: 5px;
    `}
  }

  ${Paragraph} {
    margin-bottom: 0;
    line-height: 1.25;
  }

  ul > li > ul {
    margin-left: 20px;

    ${props => props.theme.media.lessThan('mobile')`
      margin-right: 5px;
    `}
  }

  ${props => props.theme.media.lessThan('mobile')`
    ${Paragraph} {
      display: inline;
    }
  `}
`

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  ${props => props.theme.media.lessThan('mobile')`
    display: block;
  `}
`

import React from 'react'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import LandingLayout from '@layouts/LandingLayout'
import { TextPageContainer, Paragraph, PageTitle, Title } from '@common/typography'

const ImpressumPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='impressum' />
      <LandingLayout>
        <TextPageContainer>
          <PageTitle>Imprint</PageTitle>
          <Paragraph>
            Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
            Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.
          </Paragraph>
          <Paragraph>
            weCreate Data GmbH
            <br />
            Obere Augartenstrasse 2/24,
            <br />
            1020 Wien,
            <br />
            Österreich
            <br />
          </Paragraph>
          <Paragraph>
            Unternehmensgegenstand: Marketing
            <br />
            UID-Nummer: ATU73053839
            <br />
            Firmenbuchnummer: 482162m
            <br />
            Firmenbuchgericht: Wien
            <br />
            Firmensitz: Wien
          </Paragraph>
          <Paragraph>
            Tel.: 06603819240
            <br />
            E-Mail: johannes@wecreate.media
          </Paragraph>
          <Paragraph>
            Mitglied bei: WKO
            <br />
            Berufsrecht: Gewerbeordnung: www.ris.bka.gv.at
          </Paragraph>
          <Paragraph>
            Aufsichtsbehörde/Gewerbebehörde: Magistrat der Stadt Wien
            <br />
            Berufsbezeichnung: Marketing
            <br />
            Verleihungsstaat: Österreich
          </Paragraph>
          <Paragraph>
            Geschäftsführer
            <br />
            Johannes Ruisinger, Adil Sbai
          </Paragraph>
          <Paragraph>
            Beteiligungsverhältnisse
            <br />
            Gesellschafter: Hashtag Deep 24/7 GmbH 67,33%, etruvian Holdings Ltd 32,67%
          </Paragraph>
          <Paragraph>
            Quelle: Erstellt mit dem Impressum Generator von AdSimple® Linkbuilding in
            Kooperation mit schoenheitsmagazin.at
          </Paragraph>
          <br />
          <Title>EU-Streitschlichtung</Title>
          <Paragraph>
            Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
            (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
            (OS-Plattform) informieren.
            <br />
            Verbraucher haben die Möglichkeit, Beschwerden an die Online
            Streitbeilegungsplattform der Europäischen Kommission unter{' '}
            <a
              href='http://ec.europa.eu/odr?tid=121417994'
              target='_blank'
              rel='noreferrer noopener'
            >
              http://ec.europa.eu/odr?tid=121417994
            </a>{' '}
            zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem
            Impressum.
          </Paragraph>
          <Paragraph>
            Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
            verpflichtet sind, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </Paragraph>
          <Paragraph>
            Haftung für Inhalte dieser Webseite
            <br />
            Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns
            korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine
            Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen,
            speziell für jene die seitens Dritter bereitgestellt wurden.
          </Paragraph>
          <Paragraph>
            Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir
            Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
          </Paragraph>
          <br />
          <Title>Haftung für Links auf dieser Webseite</Title>
          <Paragraph>
            Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht
            verantwortlich sind. Haftung für verlinkte Websites besteht laut § 17 ECG für
            uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und haben,
            uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
            sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.
          </Paragraph>
          <Paragraph>
            Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie
            uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.
          </Paragraph>
          <br />
          <Title>Urheberrechtshinweis</Title>
          <Paragraph>
            Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem
            Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen
            der Inhalte unserer Seite rechtlich verfolgen.
          </Paragraph>
          <Paragraph>
            Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
            verletzen, bitten wir Sie uns zu kontaktieren.
          </Paragraph>
          <Paragraph>
            Bildernachweis
            <br />
            Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
            geschützt.
          </Paragraph>
          <Paragraph>
            Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
            <br />
            weCreate Data GmbH
          </Paragraph>
        </TextPageContainer>
      </LandingLayout>
    </>
  )
}

export default ImpressumPage
